import React, { useState } from "react";
import {
  AlignJustify,
  RefreshCcw,
  RotateCw,
  //   ShoppingCart,
} from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { calculateDiscount } from "src/helper/currency";
import { addProduct, finishOrder, scanProduct } from "src/redux/store/pos";
import { toggleModalTransaction } from "src/redux/store/transaction";

const Header = () => {
  const product = useSelector((state) => state.product);
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const store = JSON.parse(localStorage.getItem("store"));

  const handleSimulateBarcodeScanner = () => {
    // const arr = [6, 7, 20];
    const arr = [
      `TOP-00001-01-001-03`,
      `TOPS-001-000-001-06`,
      `TOP-00001-01-001-04`,
    ];
    const index = Math.floor(Math.random() * 3);
    for (const item of product.data) {
      const found = item.product_size.find((ps) => ps.code === arr[index]);
      if (found) {
        if (found.qty == 0) {
          alert("Sold out");
          return;
        }
        dispatch(
          addProduct({
            itemProductId: item.product_id,
            assets: item.image[0],
            product_title: item.product_alias,
            cat_title: item.product_code,
            itemPrice: parseFloat(found.price),
            itemQty: parseInt(1),
            itemProductSize: found.size,
            itemDisc: found.discount,
            itemDiscAmount: calculateDiscount(
              parseFloat(found.price),
              found.discount
            ).discAmount,
            maxQty: found.qty,
          })
        );
        return { parent: item, productSize: found };
      }
    }
    alert("Stock not found");
    return null;
  };

  const searchProductCode = (e) => {
    e.preventDefault();
    dispatch(
      scanProduct({
        txtProductCode: query,
        txtWarehouseId: store.store,
      })
    );
    setQuery("");

    // if (query == "") {
    //   alert("Product code cannot be empty");
    //   return false;
    // }
    // for (const item of product.data) {
    //   const found = item.product_size.find((ps) => ps.code === query);
    //   if (found) {
    //     if (found.qty == 0) {
    //       alert("Sold out");
    //       setQuery("");
    //       return;
    //     }
    //     dispatch(
    //       addProduct({
    //         itemProductId: item.product_id,
    //         assets: item.image[0],
    //         product_title: item.product_alias,
    //         cat_title: item.product_code,
    //         itemPrice: parseFloat(found.price),
    //         itemQty: parseInt(1),
    //         itemProductSize: found.size,
    //         itemDisc: found.discount,
    //         itemDiscAmount: calculateDiscount(
    //           parseFloat(found.price),
    //           found.discount
    //         ).discAmount,
    //         maxQty: found.qty,
    //       })
    //     );
    //     setQuery("");
    //     alert("Product Added");
    //     return { parent: item, productSize: found };
    //   }
    // }
    // setQuery("");
    // alert("Stock not found");
    // return null;
  };

  return (
    <div className="d-flex align-items-center justify-content-between mb-4">
      <div className="search-set">
        <form onSubmit={searchProductCode}>
          <div className="search-input">
            <input
              type="text"
              placeholder="Search by product code"
              className="form-control search-pos formsearch"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
            <button
              className="btn btn-searchset btn-searchset-custom"
              type="submit"
            >
              <i
                data-feather="search"
                className="feather-search text-secondary"
              />
            </button>
          </div>
        </form>
      </div>
      <div
        className="btn-row d-sm-flex align-items-center"
        style={{ padding: 0 }}
      >
        {/* <Link
          to="#"
          className="btn btn-secondary mb-xs-3"
          data-bs-toggle="modal"
          data-bs-target="#orders"
        >
          <span className="me-1 d-flex align-items-center">
            <ShoppingCart className="feather-16" />
          </span>
          View Orders
        </Link> */}
        <a
          className="btn btn-primary"
          role="button"
          onClick={() => dispatch(toggleModalTransaction())}
        >
          <span className="me-1 d-flex align-items-center">
            <RefreshCcw className="feather-16" />
          </span>
          Transaction
        </a>
        {/* <a
          className="btn btn-secondary"
          role="button"
          onClick={handleSimulateBarcodeScanner}
        >
          <span className="me-1 d-flex align-items-center">
            <AlignJustify className="feather-16" />
          </span>
          Simulate Barcode Scan
        </a> */}
        <a
          className="btn btn-info"
          role="button"
          onClick={() => dispatch(finishOrder())}
        >
          <span className="me-1 d-flex align-items-center">
            <RotateCw className="feather-16" />
          </span>
          Reset
        </a>
      </div>
    </div>
  );
};

export default Header;
