// src/features/stockOpnameSlice.js

import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "src/config/axios";

const initialState = {
  // list stock opname
  data: [],
  total: 0,
  params: {},
  loading: false,

  // detail stock opname
  detail: {
    noSo: "",
    noteSo: "",
    date: "",
    warehouseName: "",
    data: [],
    notFound: false,
  },

  productList: [],
  searchResultToast: false,
  searchResult: false,
  searchMessage: "",

  modalAdd: false,

  loadingProductList: false,
  loadingCreateSO: false,
  loadingSO: false,
  loadingTableSO: false,
  create: {
    date: null,
    warehouseID: {
      label: "",
      value: "",
    },
  },
};

export const getStockOpnameList = createAsyncThunk(
  "stock-opname/list",
  async (params, { rejectWithValue }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/v1/stock-opname`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params,
        }
      );
      const res = response.data;
      return {
        data: res.data,
        total: res.meta.total,
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
      // console.log(error);
    }
  }
);

export const getStockOpnameDetail = createAsyncThunk(
  "stock-opname/detail",
  async (params, { rejectWithValue }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/v1/stock-opname-detail`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params,
        }
      );
      const res = response.data.data;
      return {
        data: res,
        params,
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
      // console.log(error);
    }
  }
);

export const createStockOpname = createAsyncThunk(
  "stock-opname/create",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/v1/stock-opname`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const res = response.data;
      return {
        data: res,
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
      // console.log(error);
    }
  }
);

export const updateStatusStockOpname = createAsyncThunk(
  "stock-opname/update-status",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/v1/stock-opname-update-status-submit`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const res = response.data;
      return {
        data: res,
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
      // console.log(error);
    }
  }
);

export const draftStatusStockOpname = createAsyncThunk(
  "stock-opname/update-status",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/v1/stock-opname-update-status-draft`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const res = response.data;
      return {
        data: res,
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
      // console.log(error);
    }
  }
);

export const getProductList = createAsyncThunk(
  "stock-opname/product-list",
  async (_, { rejectWithValue }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const store = JSON.parse(localStorage.getItem("store"));
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/v1/product`,
        {
          warehouse_id: store.store,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let formatData = [];
      if (response.data.data) {
        const productList = response.data.data;
        formatData = productList.flatMap((item, key) => {
          return item.product_size
            ? item.product_size.map((size, index) => {
                return {
                  code: size.code,
                  name: item.product_name,
                  alias: item.product_alias,
                  discount: size.discount,
                  price: size.price,
                  size: size.size,
                  system: size.qty,
                  real: size?.real ? size?.real : 0,
                  diff: 0,
                };
              })
            : null;
        });
      }
      return {
        data: formatData,
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
      // console.log(error);
    }
  }
);

export const updateStockOpname = createAsyncThunk(
  "stock-opname-detail/update",
  async (payload, { dispatch, getState, rejectWithValue }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/v1/stock-opname-detail`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const res = response.data;
      dispatch(
        getStockOpnameDetail({
          stock_opname_id: payload.stock_opname_id,
          table: true,
        })
      );
      return {
        data: res,
        payload,
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
      // console.log(error);
    }
  }
);

export const scanProductOut = createAsyncThunk(
  "stock/opname/scan",
  async (data, { dispatch, rejectWithValue }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/v1/scan`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = response.data;

      if (result.data?.product_code) {
        // if (result.data.stock > 0) {
        dispatch(addProduct(result.data));
        // }
      }

      return {
        data: result.data,
      };
      // dispatch(
      //   getTransactionDetail({ billing_id: response.data.data.billing_id })
      // );
      // dispatch(getProduct());
      // dispatch(toggleModalCompletedPayment());
      // return {
      //   data: response.data.data,
      //   date: data.txtSalesDate,
      // };
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteStockOpname = createAsyncThunk(
  "stock/opname/delete",
  async (id, { dispatch, rejectWithValue, getState }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API}/v1/stock-opname/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = response.data;
      dispatch(getStockOpnameList(getState().stockOpname.params));
      return {
        data: result.data,
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

const stockOpnameSlice = createSlice({
  name: "stockOpname",
  initialState,
  reducers: {
    updateStock: (state, action) => {
      //   console.log("before", current(state));
      const existingProduct = state.detail.data.find(
        (item) => item?.product_code === action.payload.product_code
      );
      //   console.log("existing ", state.productList);
      //   console.log("existing ", existingProduct);
      if (existingProduct) {
        if (action.payload.type == "plus") {
          if (existingProduct.stock >= existingProduct.qty + 1) {
            existingProduct.qty += 1;
            state.searchResultToast = true;
            state.searchResult = true;
            state.searchMessage = `${existingProduct.product_code} added`;
          }
        } else {
          if (existingProduct.qty > 1) {
            existingProduct.qty -= 1;
            state.searchResultToast = true;
            state.searchResult = true;
            state.searchMessage = `${existingProduct.product_code} reduced`;
          }
        }
      } else {
        state.searchResultToast = true;
        state.searchResult = false;
        state.searchMessage = `Not found`;
      }
      //   console.log("after", current(state));
    },
    changeStock: (state, action) => {
      const existingProduct = state.detail.data.find(
        (item) => item?.product_code === action.payload.product_code
      );
      if (existingProduct) {
        if (parseInt(action.payload.amount) > 0) {
          existingProduct.qty = parseInt(action.payload.amount);
        } else {
          existingProduct.qty = 0;
        }
      }
    },
    closeToastNotification: (state) => {
      state.searchResultToast = false;
    },
    setDate: (state, action) => {
      state.create.date = action.payload;
    },
    setWarehouseID: (state, action) => {
      state.create.warehouseID = action.payload;
    },
    setNoteSo: (state, action) => {
      state.detail.noteSo = action.payload;
    },
    toggleModalAdd: (state) => {
      if (!state.modalAdd) {
        state.create.date = null;
        state.create.warehouseID.label = "";
        state.create.warehouseID.value = "";
      }
      state.modalAdd = !state.modalAdd;
    },
    addProduct: (state, action) => {
      //   state.totalAmount += action.payload.price * action.payload.quantity;
      const existingProduct = state.detail.data.find(
        (item) => item.product_code === action.payload.product_code
      );
      if (existingProduct) {
        existingProduct.qty =
          // parseInt(existingProduct.qty) + parseInt(action.payload.qty);
          parseInt(existingProduct.qty) + 1;
      } else {
        state.detail.data.push({ ...action.payload, qty: 1 });
      }
    },
    removeProduct: (state, action) => {
      const index = state.detail.data.findIndex(
        (item) => item.product_code === action.payload.product_code
      );
      if (index !== -1) {
        state.detail.data.splice(index, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProductList.fulfilled, (state, action) => {
      state.productList = action.payload.data;
      state.loadingProductList = false;
    });
    builder.addCase(getProductList.pending, (state) => {
      state.loadingProductList = true;
    });
    builder.addCase(getProductList.rejected, (state) => {
      state.loadingProductList = false;
    });
    builder.addCase(createStockOpname.fulfilled, (state) => {
      state.loadingCreateSO = false;
      state.modalAdd = false;
    });
    builder.addCase(createStockOpname.pending, (state) => {
      state.loadingCreateSO = true;
    });
    builder.addCase(createStockOpname.rejected, (state) => {
      state.loadingCreateSO = false;
    });
    builder.addCase(getStockOpnameList.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.total = action.payload.total;
      state.loading = false;
    });
    builder.addCase(getStockOpnameList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStockOpnameList.rejected, (state) => {
      state.loadingCreateSO = false;
      state.loading = false;
    });
    builder.addCase(updateStockOpname.fulfilled, (state, action) => {
      state.searchResultToast = true;
      state.searchResult = true;
      state.searchMessage = `${action.payload.payload.product_code} added`;
    });
    builder.addCase(updateStockOpname.pending, (state) => {
      state.searchMessage = ``;
    });
    builder.addCase(updateStockOpname.rejected, (state) => {
      state.searchResultToast = true;
      state.searchResult = false;
      state.searchMessage = `Not found`;
    });

    builder.addCase(scanProductOut.fulfilled, (state, action) => {
      // console.log("payload ", action.payload);
      if (action.payload.data.product_code) {
        state.searchResultToast = true;
        state.searchResult = true;
        state.searchMessage = `${action.payload.data.product_code} added`;
      } else {
        state.searchResultToast = true;
        state.searchResult = false;
        state.searchMessage = `Not found`;
      }
    });
    builder.addCase(scanProductOut.pending, (state) => {
      state.searchMessage = ``;
    });
    builder.addCase(scanProductOut.rejected, (state) => {
      state.searchResultToast = true;
      state.searchResult = false;
      state.searchMessage = `Not found`;
    });

    builder.addCase(getStockOpnameDetail.fulfilled, (state, action) => {
      if (action.payload.data.details.length) {
        const temp = action.payload.data.details.map((item) => {
          return {
            ...item,
            qty: item.qty_fisik,
            stock: item.qty_sistem,
            product_code: item.product_code_size,
          };
        });
        state.detail.data = temp;
      } else {
        state.detail.data = action.payload.data.details;
      }
      state.detail.date = action.payload.data.date;
      state.detail.warehouseName = action.payload.data.warehouse_name;
      state.detail.noSo = action.payload.data.no_so;
      state.detail.noteSo = action.payload.data.so_note;
      state.detail.status = action.payload.data.status;
      if (action.payload.params.table) {
        state.loadingTableSO = false;
      } else {
        state.loadingSO = false;
      }
      state.detail.notFound = false;
    });
    builder.addCase(getStockOpnameDetail.pending, (state, action) => {
      // state.loadingSO = true;
      const payload = action.meta.arg;

      state.detail.notFound = false;
      if (payload.table) {
        state.loadingTableSO = true;
      } else {
        state.loadingSO = true;
      }
    });
    builder.addCase(getStockOpnameDetail.rejected, (state, action) => {
      // state.loadingSO = false;
      const payload = action.meta.arg;

      state.detail.notFound = true;
      if (payload.table) {
        state.loadingTableSO = false;
      } else {
        state.loadingSO = false;
      }
    });
  },
});

export const {
  updateStock,
  changeStock,
  closeToastNotification,
  setDate,
  setWarehouseID,
  toggleModalAdd,
  setNoteSo,
  addProduct,
  removeProduct,
} = stockOpnameSlice.actions;
export default stockOpnameSlice.reducer;
