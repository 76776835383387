import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toggleModalPrint } from "src/redux/store/transferStockOut";
import "./style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Printer } from "react-feather";
import { printLetter, printReceipt, printStock } from "src/helper/print";
import moment from "moment";
import PrintableComponent from "./PrintableComponent";

const PrintModal = () => {
  const transferStockOut = useSelector((state) => state.transferStockOut);
  const dispatch = useDispatch();

  const handlePrint = () => {
    printStock("skb");
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={transferStockOut.modalPrint}
      onHide={() => dispatch(toggleModalPrint({ buttonPrint: false }))}
    >
      <Modal.Header closeButton>
        <div className="d-flex flex-column">
          <Modal.Title>Preview Print</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body id="skb" className="p-5">
        {/* <div className="text-center pb-3">
          <h4 className="text-uppercase">Surat Keluar Barang</h4>
        </div> */}

        <PrintableComponent />
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex align-items-center justify-content-end gap-2">
          <button
            className="btn btn-secondary"
            onClick={() => dispatch(toggleModalPrint({ buttonPrint: false }))}
          >
            Cancel
          </button>
          {!transferStockOut.buttonPrint ? (
            <button
              className="btn btn-primary d-flex align-items-center justify-content-center"
              onClick={handlePrint}
            >
              <Printer size={18} />
              <span className="ms-2">Print</span>
            </button>
          ) : null}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PrintModal;
