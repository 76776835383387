import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toggleModalAdd } from "src/redux/store/stockopname";
import SelectAsync from "react-select/async";
import Select from "react-select";
import Table from "src/core/pagination/datatable";
import { Calendar, PlusCircle, Trash2 } from "react-feather";
import { Divider } from "antd";
import "./style.css";
import axios from "src/config/axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  createStockOpname,
  setDate,
  setWarehouseID,
} from "src/redux/store/stockopname";
import { Navigate, redirect, useNavigate } from "react-router-dom";
import moment from "moment";

const AddModal = () => {
  let navigate = useNavigate();

  const transferStockOut = useSelector((state) => state.transferStockOut);
  const stock = useSelector((state) => state.stockOpname);
  const warehouse = useSelector((state) => state.warehouse);
  const [valid, setValid] = useState(false);
  const dispatch = useDispatch();
  const handleDateChange = (date) => {
    dispatch(setDate(date));
  };
  const user = JSON.parse(localStorage.getItem("userData"));
  const store = JSON.parse(localStorage.getItem("store"));

  const handleCreate = async (e) => {
    e.preventDefault();
    // async function here
    try {
      const payload = {
        warehouse_id: stock.create.warehouseID.value,
        date: moment(stock.create.date).format("YYYY-MM-DD"),
        created_by: user.id,
      };
      const res = await dispatch(createStockOpname(payload)).unwrap();
      navigate(`/stock-opname/create/${res.data.id_so}`);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (stock.create.warehouseID.value && stock.create.date) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [stock]);

  return (
    <Modal
      // size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={stock.modalAdd}
      onHide={() => dispatch(toggleModalAdd())}
    >
      <Modal.Header closeButton>
        <div className="d-flex flex-column">
          <Modal.Title>Create Stock Opname</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleCreate}>
          <div className="row">
            <div className="col-lg-6">
              <div className="input-blocks">
                <label>Date</label>
                <div className="input-blocks">
                  <Calendar className="info-img" />
                  <div className="input-groupicon">
                    <DatePicker
                      selected={stock.create.date}
                      onChange={handleDateChange}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Choose Date"
                      className="datetimepicker"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-blocks">
                <label>Warehouse</label>
                <Select
                  classNamePrefix="react-select"
                  // options={warehouse.data.map((item) => {
                  //   return {
                  //     value: item.warehouse_id,
                  //     label: `${item.warehouse_code} | ${item.warehouse_name}`,
                  //   };
                  // })}
                  options={[{ value: store.store, label: store.store_name }]}
                  value={stock.create.warehouseID}
                  onChange={(e) => dispatch(setWarehouseID(e))}
                />
              </div>
            </div>
            {/* <div className="col-lg-6">
            <div className="input-blocks">
              <label>Note</label>
              <input
                type="text"
                className="form-control"
                value={transferStockOut.addItem.note}
                onChange={(e) => dispatch(setNote(e.target.value))}
              />
            </div>
          </div> */}
          </div>

          <div className="modal-footer-btn">
            <button
              type="button"
              className="btn btn-cancel me-2"
              onClick={() => dispatch(toggleModalAdd())}
            >
              Cancel
            </button>
            <button
              disabled={!valid || stock.loadingCreateSO}
              type="submit"
              className="btn btn-submit"
            >
              Create
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddModal;
