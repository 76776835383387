// src/features/transactionSlice.js

import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "src/config/axios";
import { calculateDiscount } from "src/helper/currency";
import Swal from "sweetalert2";

const initialState = {
  data: [],
  total: 0,
  params: {},
  loading: false,

  modalOptions: false,
  modalExchageReason: false,
  modalProductSelect: false,
  modalQtySelect: false,
  modalNewItem: false,
  modalExchangeSummary: false,
  modalExchangeComplete: false,
  returType: "",
  returReason: null,
  returReasonNote: "",
  returProduct: {},
  // for new item exchange
  returCart: [],
  returTotalAmount: 0,
  returTotalDiscount: 0,
  exchangeNotes: "",

  modalTransaction: false,
  loadingDetailTransaction: false,

  loadingRetur: false,
  errorRetur: "",

  modalReprint: false,
  modalReprintRetur: false,
  modalRetur: false,
  modalCompletedRetur: false,
  dataRetur: [],
  dataReturTotal: 0,
  totalRefund: 0,
  detailTransaction: {
    billingRetur: null,
    billingDate: null,
    billingCode: 0,
    billingDiscount: 0,
    billingSubtotal: 0,
    billingGrandTotal: 0,
    referenceId: null,
    data: [],
  },
  detailRetur: {
    data: [],
    billingCode: "",
    reason: "",
    totalRefund: 0,
  },
};

export const getTransaction = createAsyncThunk(
  "transaction",
  async (params) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const store = JSON.parse(localStorage.getItem("store"));
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/v1/sales-invoice-list`,
        {
          warehouse_id: store.store,
          ...params,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          // params: {
          //   warehouse_id: user.warehouse_id,
          // },
        }
      );
      const result = response.data;
      return {
        data: result.data.data,
        total: result.data.total,
        params,
      };
    } catch (error) {
      console.log(error);
    }
  }
);

export const getTransactionDetail = createAsyncThunk(
  "transaction-detail",
  async (params, { dispatch }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/v1/sales-invoice-detail`,
        {
          billing_id: params.billing_id,
          ...params,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // dispatch(toggleModalReprint());
      const retur = response.data.data.map((item) => {
        return {
          ...item,
          checked: false,
          // qty_retur: item.qty,
          qty_retur: item.qty_retur_outstanding,
        };
      });
      return {
        data: response.data.data,
        billing: response.data,
        retur,
      };
    } catch (error) {
      console.log(error);
    }
  }
);

export const getReturDetail = createAsyncThunk(
  "retur-detail",
  async (params, { dispatch }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const token = localStorage.getItem("token");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/v1/sales-invoice-retur/${params}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = response.data.data;

      return {
        data: result.details,
        billingCode: result.billing_code,
        reason: result.reason,
        totalRefund: result.total_refund,
      };
    } catch (error) {
      console.log(error);
    }
  }
);

export const scanProductOut = createAsyncThunk(
  "transaction/scan",
  async (data, { dispatch, rejectWithValue }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/v1/scan`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = response.data;

      if (result.data) {
        if (result.data.stock > 0) {
          dispatch(addCart({ ...result.data, qty: 1 }));
        }
      }

      return {
        data: result.data,
      };
      // dispatch(
      //   getTransactionDetail({ billing_id: response.data.data.billing_id })
      // );
      // dispatch(getProduct());
      // dispatch(toggleModalCompletedPayment());
      // return {
      //   data: response.data.data,
      //   date: data.txtSalesDate,
      // };
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const returProduct = createAsyncThunk(
  "transaction/retur",
  async (data, { dispatch, rejectWithValue }) => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/v1/sales-invoice-retur`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = response.data;

      return {
        data: result.data,
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  }
);

const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    toggleModalTransaction: (state) => {
      if (!state.modalTransaction) {
        state.modalReprint = false;
        state.modalRetur = false;
      }
      state.modalTransaction = !state.modalTransaction;
    },
    toggleModalReprint: (state) => {
      //   if (state.)
      if (!state.modalReprint) {
        state.modalTransaction = false;
      }
      state.modalReprint = !state.modalReprint;
    },
    toggleModalReprintRetur: (state) => {
      //   if (state.)
      if (!state.modalReprintRetur) {
        state.modalTransaction = false;
      }
      state.modalReprintRetur = !state.modalReprintRetur;
    },
    toggleModalRetur: (state) => {
      //   if (state.)
      if (!state.modalRetur) {
        state.modalTransaction = false;
      }
      if (state.modalRetur) {
        state.dataRetur = [];
      }
      state.modalRetur = !state.modalRetur;
    },
    toggleModalCompletedRetur: (state) => {
      //   if (state.)
      state.modalRetur = false;
      state.modalCompletedRetur = !state.modalCompletedRetur;
    },
    toggleModalPrints: (state) => {
      //   if (state.)
      state.modalReprint = !state.modalReprint;
    },
    closeModalTransaction: (state) => {
      state.modalTransaction = false;
    },
    closeModalCompletedRetur: (state) => {
      state.modalTransaction = true;
      state.modalCompletedRetur = false;
    },
    closeModalReprint: (state) => {
      state.modalReprint = false;
    },
    setReturData: (state, action) => {
      state.dataRetur = action.payload;
    },
    setModalTransaction: (state, action) => {
      state.modalTransaction = action.payload;
    },
    setModalReturOptions: (state, action) => {
      state.modalOptions = action.payload;
    },
    setReturType: (state, action) => {
      state.returType = action.payload;
    },
    setModalRetur: (state, action) => {
      state.modalRetur = action.payload;
    },
    setModalExchangeReason: (state, action) => {
      state.modalExchageReason = action.payload;
    },
    setModalProductSelect: (state, action) => {
      state.modalProductSelect = action.payload;
    },
    setModalQtySelect: (state, action) => {
      state.modalQtySelect = action.payload;
    },
    setModalNewItem: (state, action) => {
      state.modalNewItem = action.payload;
    },
    setModalExchangeSummary: (state, action) => {
      if (action.payload) {
        // const total = state.dataRetur.reduce(
        //   (accumulator, item) => accumulator + item.grandtotal,
        //   0
        // );
        const total = state.dataRetur.reduce((accumulator, item) => {
          if (item.checked) {
            const total_amount = item.billing_price * item.qty_retur;
            const discount = calculateDiscount(
              total_amount,
              item.billing_discount
            ).discAmount;
            return accumulator + total_amount - discount;
          }
          return accumulator;
        }, 0);
        state.dataReturTotal = total;
        if (state.returType == "exchange") {
          const refund =
            state.returTotalAmount - state.returTotalDiscount - total;
          state.totalRefund = refund;
        } else {
          state.totalRefund = total;
        }
      }
      state.modalExchangeSummary = action.payload;
    },
    setModalExchangeComplete: (state, action) => {
      state.modalExchangeComplete = action.payload;
    },
    setReturReason: (state, action) => {
      state.returReason = action.payload;
    },
    setReturReasonNote: (state, action) => {
      state.returReasonNote = action.payload;
    },
    setExchangeNote: (state, action) => {
      state.exchangeNotes = action.payload;
    },
    resetDataRetur: (state) => {
      state.dataRetur = [];
    },
    resetReturCart: (state) => {
      state.returCart = [];
      state.returTotalAmount = 0;
      state.returTotalDiscount = 0;
    },
    resetDetailTransaction: (state) => {
      state.detailTransaction = {
        billingRetur: null,
        billingDate: null,
        billingCode: 0,
        billingDiscount: 0,
        billingSubtotal: 0,
        billingGrandTotal: 0,
        referenceId: 0,
        data: [],
      };
    },
    resetReturProduct: (state) => {
      state.returProduct = {};
    },
    resetChecked: (state) => {
      const temp = state.dataRetur.map((item) => {
        return {
          ...item,
          checked: false,
        };
      });
      state.dataRetur = temp;
    },
    addProduct: (state, action) => {
      state.returProduct = action.payload;
    },
    addCart: (state, action) => {
      //   state.items.push(action.payload);
      //   state.totalAmount += action.payload.price * action.payload.quantity;
      const existingProduct = state.returCart.find(
        (item) => item.product_code == action.payload.product_code
      );
      if (existingProduct) {
        if (existingProduct.stock >= existingProduct.qty + action.payload.qty) {
          existingProduct.qty += action.payload.qty;
          existingProduct.product_discount += action.payload.product_discount;
          const totalAmount = action.payload.product_price * action.payload.qty;
          state.returTotalAmount += totalAmount;
          state.returTotalDiscount += calculateDiscount(
            totalAmount,
            action.payload.product_discount
          ).discAmount;
        }
      } else {
        state.returCart.push(action.payload);
        const totalAmount = action.payload.product_price * action.payload.qty;
        state.returTotalAmount += totalAmount;
        state.returTotalDiscount += calculateDiscount(
          totalAmount,
          action.payload.product_discount
        ).discAmount;
      }
    },
    incrementQuantity: (state, action) => {
      const item = state.returCart.find(
        (item) => item.product_code === action.payload.product_code
      );
      if (item) {
        item.qty += 1;
        item.discount += action.payload.product_discount;
        const totalAmount = item.product_price;
        state.returTotalAmount += totalAmount;
        state.returTotalDiscount += calculateDiscount(
          totalAmount,
          action.payload.product_discount
        ).discAmount;
      }
    },
    decrementQuantity: (state, action) => {
      const item = state.returCart.find(
        (item) => item.product_code === action.payload.product_code
      );
      if (item) {
        if (item.qty - 1 <= 0) {
          const index = state.returCart.findIndex(
            (item) => item.product_code === action.payload.product_code
          );
          if (index !== -1) {
            const totalAmount =
              state.returCart[index].product_price * state.returCart[index].qty;
            state.returTotalAmount -= totalAmount;
            state.returTotalDiscount -= calculateDiscount(
              totalAmount,
              action.payload.product_discount
            ).discAmount;
            state.returCart.splice(index, 1);
          }
        } else {
          item.qty -= 1;
          item.discount -= action.payload.product_discount;
          const totalAmount = item.product_price;
          state.returTotalAmount -= totalAmount;
          state.returTotalDiscount -= calculateDiscount(
            totalAmount,
            action.payload.product_discount
          ).discAmount;
        }
      }
    },
    resetRetur: (state) => {
      // state.dataRetur = [];
      state.returTotalDiscount = 0;
      state.returTotalAmount = 0;
      state.returCart = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTransaction.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.total = action.payload.total;
      state.params = action.payload.params;
      state.loading = false;
    });
    builder.addCase(getTransaction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTransaction.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getTransactionDetail.fulfilled, (state, action) => {
      state.dataRetur = action.payload.retur;
      state.detailTransaction.data = action.payload.data;
      state.detailTransaction.billingCode = action.payload.billing.billing_code;
      state.detailTransaction.billingDate = action.payload.billing.billing_date;
      state.detailTransaction.billingDiscount =
        action.payload.billing.aggregated_data.discount;
      state.detailTransaction.billingSubtotal =
        action.payload.billing.aggregated_data.subtotal;
      state.detailTransaction.billingGrandTotal =
        action.payload.billing.aggregated_data.grandtotal;
      state.detailTransaction.billingPaid = action.payload.billing.billing_paid;
      state.detailTransaction.warehouseName =
        action.payload.billing.warehouse_name;
      state.detailTransaction.warehouseAddress =
        action.payload.billing.warehouse_address;
      state.detailTransaction.cashierName = action.payload.billing.cashier_name;
      state.detailTransaction.referenceId = action.payload.billing.reference_id;
      state.detailTransaction.billingPaidType =
        action.payload.billing.billing_paid_type;
      state.detailTransaction.billingRetur =
        action.payload.billing.billing_retur;
      state.loadingDetailTransaction = false;
    });
    builder.addCase(getTransactionDetail.pending, (state) => {
      state.loadingDetailTransaction = true;
    });
    builder.addCase(getTransactionDetail.rejected, (state) => {
      state.loadingDetailTransaction = false;
    });

    builder.addCase(returProduct.pending, (state) => {
      state.loadingRetur = true;
      state.errorRetur = "";
    });
    builder.addCase(returProduct.rejected, (state, action) => {
      state.loadingRetur = false;
      state.errorRetur = action.payload;
    });
    builder.addCase(returProduct.fulfilled, (state) => {
      state.loadingRetur = false;
      state.modalExchangeSummary = false;
      state.modalExchangeComplete = true;
      state.errorRetur = "";
    });

    builder.addCase(getReturDetail.pending, (state) => {
      // state.loadingRetur = true;
      // state.errorRetur = "";
    });
    builder.addCase(getReturDetail.rejected, (state) => {
      // state.loadingRetur = false;
      // state.errorRetur = action.payload;
    });
    builder.addCase(getReturDetail.fulfilled, (state, action) => {
      state.detailRetur.data = action.payload.data;
      state.detailRetur.billingCode = action.payload.billingCode;
      state.detailRetur.reason = action.payload.reason;
      state.detailRetur.totalRefund = action.payload.totalRefund;
    });

    builder.addCase(scanProductOut.fulfilled, (state, action) => {
      if (action.payload.data.stock) {
        if (action.payload.data.stock > 0) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Product Added",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `Product is Out of Stock`,
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `Product Not Found`,
        });
      }
      state.selectedItem = {};
    });
    builder.addCase(scanProductOut.pending, (state) => {});
    builder.addCase(scanProductOut.rejected, (state, action) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${action.payload.message}`,
      });
      state.selectedItem = {};
    });
  },
});

export const {
  toggleModalTransaction,
  toggleModalReprint,
  toggleModalReprintRetur,
  toggleModalPrints,
  toggleModalRetur,
  toggleModalCompletedRetur,
  closeModalTransaction,
  closeModalReprint,
  closeModalCompletedRetur,
  setReturData,
  setModalTransaction,

  setModalReturOptions,
  setReturType,
  setModalRetur,
  setReturReason,
  setReturReasonNote,
  setModalExchangeReason,
  setModalProductSelect,
  setModalQtySelect,
  setModalNewItem,
  setModalExchangeSummary,
  setExchangeNote,
  setModalExchangeComplete,

  resetDetailTransaction,
  resetDataRetur,
  resetReturProduct,
  resetReturCart,
  resetChecked,
  uncheckedDataRetur,
  resetRetur,

  addProduct,
  addCart,
  incrementQuantity,
  decrementQuantity,
} = transactionSlice.actions;
export default transactionSlice.reducer;
