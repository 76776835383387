import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  createTransferStockIn,
  reset,
  setDate,
  // setItemNote,
  // setItemQty,
  setNote,
  setStore,
} from "src/redux/store/transferStockIn";
import {
  setRefNumber,
  toggleModalAdd,
  setModalAdd,
} from "src/redux/store/transferStockIn";
import Select from "react-select";
import { Calendar } from "react-feather";
import "./style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Divider } from "antd";
import Table from "src/core/pagination/datatable-ingoing-goods";
import {
  clearDetail,
  getOutstanding,
  getTransferStockOutDetail,
  setItemNoteDetail,
  setItemQtyDetail,
} from "src/redux/store/transferStockOut";
import moment from "moment";

const AddModal = () => {
  const transferStockOut = useSelector((state) => state.transferStockOut);
  const transferStockIn = useSelector((state) => state.transferStockIn);
  const warehouse = useSelector((state) => state.warehouse);
  const [references, setReferences] = useState([]);
  const user = JSON.parse(localStorage.getItem("userData"));
  const store = JSON.parse(localStorage.getItem("store"));
  const [disabled, setDisabled] = useState(true);

  const dispatch = useDispatch();

  const handleDateChange = (date) => {
    dispatch(setDate(date));
  };

  const columns = [
    {
      title: "No",
      key: "index",
      render: (value, item, index) => index + 1,
    },
    {
      title: "Product Code",
      dataIndex: "product_code",
      // width: 120,
      render: (value, item, index) => {
        return (
          <div
            className="text-truncate"
            style={{
              maxWidth: "120px",
              wordWrap: "break-word",
              wordBreak: "break-word",
            }}
          >
            {item.product_code}
          </div>
        );
      },
    },
    {
      title: "Product Alias",
      dataIndex: "product_alias",
      // width: 180,
      render: (value, item, index) => {
        return (
          <div
            className="text-truncate"
            style={{
              maxWidth: "180px",
              wordWrap: "break-word",
              wordBreak: "break-word",
            }}
          >
            {item.product_alias}
          </div>
        );
      },
    },
    {
      title: "Size",
      dataIndex: "product_size",
    },
    {
      title: "Qty",
      dataIndex: "qty",
    },
    {
      title: "Qty Outstanding",
      dataIndex: "qty_outstanding",
    },
    {
      title: "Received Qty",
      dataIndex: "receipent_qty",
      // width: 120,
      render: (value, item, index) => {
        return (
          <div>
            <input
              type="number"
              className="form-control"
              style={{ maxWidth: "100px" }}
              min={1}
              max={item.qty_outstanding}
              value={item?.receipent_qty}
              onChange={(e) =>
                dispatch(
                  setItemQtyDetail({
                    id: item.stock_order_detail_id,
                    qty: e.target.value,
                  })
                )
              }
            />
          </div>
        );
      },
    },
    {
      title: "Sender Note",
      dataIndex: "stock_order_detail_note",
      // width: 120,
      render: (value, item, index) => {
        return (
          <div
            className="text-truncate"
            style={{
              maxWidth: "120px",
              wordWrap: "break-word",
              wordBreak: "break-word",
            }}
          >
            {item.stock_order_detail_note}
          </div>
        );
      },
    },
    {
      title: "Receipent Note",
      dataIndex: "receipent_note",
      render: (value, item, index) => {
        return (
          <div>
            <input
              type="text"
              className="form-control"
              style={{ maxWidth: "150px" }}
              defaultValue={item?.receipent_note}
              onChange={(e) =>
                dispatch(
                  setItemNoteDetail({
                    id: item.stock_order_detail_id,
                    note: e.target.value,
                  })
                )
              }
            />
          </div>
        );
      },
    },
  ];

  const handleSubmit = () => {
    const itemProduct = transferStockOut.detail.data.map((item) => {
      return {
        itemProductId: item.product_id,
        ItemStockOutDetailId: item.stock_order_detail_id,
        itemProductSize: item.product_size,
        itemQty: item.qty,
        itemReceivedQty: item.receipent_qty,
        itemReceivedNote: item.receipent_note,
      };
    });
    const data = {
      txtStockDate: transferStockIn.addItem.createdDate
        ? moment(transferStockIn.addItem.createdDate).format("YYYY-MM-DD")
        : null,
      txtStockOrderId: transferStockIn.addItem.refNumber.value,
      txtNote: transferStockIn.addItem.note,
      // txtWarehouse: user.warehouse_id,
      txtWarehouse: store.store,
      itemProduct,
    };
    dispatch(createTransferStockIn(data));
  };

  const handleChangeStore = (e) => {
    const warehouse_id = e.value;
    dispatch(getOutstanding({ warehouse_id }));
    // const masterReference = transferStockOut.data.filter(
    //   (item) =>
    //     item.stock_order_reference_id == warehouse_id &&
    //     item.stock_order_status == 1
    // );
    // setReferences(masterReference);
    dispatch(setStore(e));
  };

  const handleChangeRefNumber = (e) => {
    const stock_order_id = e.value;
    dispatch(setRefNumber(e));
    dispatch(
      getTransferStockOutDetail({
        stock_order_id,
      })
    );
  };

  const handleClose = () => {
    dispatch(setModalAdd(false));
    dispatch(reset());
    dispatch(clearDetail());
  };

  useEffect(() => {
    if (
      transferStockIn.addItem.createdDate &&
      transferStockIn.addItem.refNumber.value &&
      transferStockIn.addItem.store.value
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [transferStockOut]);

  return (
    <Modal
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={transferStockIn.modalAdd}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <div className="d-flex flex-column">
          <Modal.Title>Create New Incoming Goods</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-lg">
            <div className="input-blocks">
              <label>Created Date</label>
              <div className="input-blocks">
                <Calendar className="info-img" />
                <div className="input-groupicon">
                  <DatePicker
                    selected={transferStockIn.addItem.createdDate}
                    onChange={handleDateChange}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Choose Date"
                    className="datetimepicker"
                    wrapperClassName="w-100"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="input-blocks">
              <label>From Store</label>
              {/* <Select
                classNamePrefix="react-select"
                options={optionsSelosyLogerro}
                value={transferStockOut.addItem.store}
                onChange={(e) => dispatch(setStore(e))}
              /> */}
              <Select
                classNamePrefix="react-select"
                options={warehouse.data
                  .filter((item) => item.warehouse_id !== store.store)
                  .map((item) => {
                    return {
                      value: item.warehouse_id,
                      label: `${item.warehouse_code} | ${item.warehouse_name}`,
                    };
                  })}
                value={transferStockIn.addItem.store}
                onChange={(e) => handleChangeStore(e)}
              />
            </div>
          </div>
          <div className="col">
            <div className="input-blocks">
              <label>Ref Number</label>
              <Select
                classNamePrefix="react-select"
                // isClearable={true} // Add this line to enable clearing the select
                defaultValue={null}
                options={transferStockOut.references.map((item) => {
                  return {
                    value: item.stock_order_id,
                    label: item.stock_order_code,
                  };
                })}
                value={transferStockOut.addItem.refNumber}
                onChange={(e) => handleChangeRefNumber(e)}
              />
            </div>
          </div>
          <div className="col-lg-12">
            <div className="input-blocks">
              <label>Note</label>
              {/* <input
                type="text"
                className="form-control"
                value={transferStockOut.addItem.note}
                onChange={(e) => dispatch(setNote(e.target.value))}
              /> */}
              <textarea
                className="form-control"
                value={transferStockIn.addItem.note}
                onChange={(e) => dispatch(setNote(e.target.value))}
              ></textarea>
            </div>
          </div>
        </div>

        <Divider className="text-secondary">List Product</Divider>

        <Table
          tableClassName={"tight-table"}
          className="table datanew"
          columns={columns}
          dataSource={transferStockOut.detail.data}
          pagination={false}
          //   loading={transferStockOut.loading}
          //   total={transferStockOut.total}
          // handleChange={handleChange}
        />

        <div className="modal-footer-btn">
          <button
            type="button"
            className="btn btn-cancel me-2"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-submit"
            disabled={disabled}
            onClick={handleSubmit}
          >
            Create
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddModal;
