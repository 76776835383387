import React, { useEffect, useState } from "react";
import "../style.css";
import UserSelect from "../user-select";
import CheckIn from "../check-in";
import { useDispatch, useSelector } from "react-redux";
import { getListAbsen, getListUser } from "src/redux/store/user";

export default function Absen() {
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);

  useEffect(() => {
    dispatch(getListUser());
    dispatch(getListAbsen());
  }, []);

  return (
    <>
      <div className="absen-wrapper">
        <div className="absen-header bg-secondary ">
          <h5 className="text-light">Absence</h5>
        </div>
        <div className="absen-content">
          <UserSelect setChecked={setChecked} />
        </div>
      </div>
    </>
  );
}
