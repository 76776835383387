import moment from "moment";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { rupiahCurrency } from "src/helper/currency";
import {
  setCardNumber,
  setNote,
  setRefCode,
  submitTransaction,
  toggleModalCardPayment,
  // toggleModalCompletedPayment,
} from "src/redux/store/pos";

export const cardMethod = [
  "DEBIT CARD - MANDIRI",
  "CREDIT CARD - MANDIRI VIMAS",
  "CREDIT CARD - BCA AMEX",
  "CREDIT CARD - BCA VIMAS",
];

const CardPayment = () => {
  const pos = useSelector((state) => state.pos);
  const dispatch = useDispatch();
  const staff = JSON.parse(localStorage.getItem("staff"));
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      txtSalesDate: moment().format("YYYY-MM-DD"),
      txtCustomer: "0",
      txtPaid: pos.cash,
      txtSalesNote: pos.note,
      itemProduct: pos.items,
      // txtPaidType: pos.paymentType,
      txtPaidType: [
        {
          paymentMethod: pos.paymentType,
          total: pos.cash,
        },
      ],
      txtPaidRef: pos.refCode,
      txtPaidNumber: pos.cardNumber,
      user_id: staff?.id,
    };
    try {
      dispatch(submitTransaction(data));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={pos.modalCardPayment}
      onHide={() => dispatch(toggleModalCardPayment())}
    >
      <Modal.Header closeButton>
        <Modal.Title>{pos.paymentType} Payment</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          {pos.errorTransaction && (
            <div className="alert alert-outline-danger alert-dismissible fade show">
              {JSON.stringify(pos.errorTransaction)}
            </div>
          )}
          <div className="d-flex flex-column">
            <div className="text-center">Total Amount</div>
            {/* <h2 className="text-center ">4500.00</h2> */}
            <h2 className="text-center mt-2">
              {rupiahCurrency.format(pos.totalAmount - pos.totalDiscount)}
            </h2>
          </div>
          <div className="input-block">
            <label>Ref Code</label>
            <input
              className="form-control"
              type="text"
              placeholder=""
              value={pos.refCode}
              onChange={(e) => dispatch(setRefCode(e.target.value))}
            />
          </div>
          {cardMethod.includes(pos.paymentType) ? (
            <div className="input-block mt-3">
              <label>Card Number</label>
              <input
                className="form-control"
                type="text"
                placeholder=""
                value={pos.cardNumber}
                onChange={(e) => dispatch(setCardNumber(e.target.value))}
              />
            </div>
          ) : null}

          <div className="input-block mt-3">
            <label>Note</label>
            <textarea
              className="form-control"
              placeholder=""
              value={pos.note}
              onChange={(e) => dispatch(setNote(e.target.value))}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center gap-1">
          <Button
            variant="secondary"
            disabled={pos.loadingTransaction}
            onClick={() => dispatch(toggleModalCardPayment())}
          >
            {pos.loadingTransaction ? (
              <span>-</span>
            ) : (
              <span>Back to Payment Method</span>
            )}
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={pos.loadingTransaction}
            // onClick={() => dispatch(toggleModalCompletedPayment())}
          >
            {pos.loadingTransaction ? `Processing...` : `Process`}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default CardPayment;
