import React from "react";
import { useSelector } from "react-redux";
import { rupiahCurrency } from "src/helper/currency";

const Summary = () => {
  const staging = useSelector((state) => state.pos);
  return (
    <div className="order-total" style={{ userSelect: "none" }}>
      <table className="table table-responsive table-borderless">
        <tbody>
          <tr>
            <td>Sub Total</td>
            <td className="text-end">
              {rupiahCurrency.format(staging.totalAmount)}
            </td>
          </tr>
          {/* <tr>
            <td>Tax (GST 5%)</td>
            <td className="text-end">$40.21</td>
          </tr>
          <tr>
            <td>Shipping</td>
            <td className="text-end">$40.21</td>
          </tr>
          <tr>
            <td>Sub Total</td>
            <td className="text-end">$60,454</td>
          </tr> */}
          <tr>
            <td className="danger">Discount</td>
            <td className="danger text-end">
              {rupiahCurrency.format(staging.totalDiscount)}
            </td>
          </tr>
          <tr>
            <td>Total</td>
            <td className="text-end fw-bolder pt-2">
              {rupiahCurrency.format(
                staging.totalAmount - staging.totalDiscount
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Summary;
