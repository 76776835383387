import React, { useEffect, useState } from "react";
// import Breadcrumbs from "src/core/breadcrumbs";
import ImageWithBasePath from "src/core/img/imagewithbasebath";
import Select from "react-select";
import { Link } from "react-router-dom";
import {
  Archive,
  Calendar,
  User,
  Trash2,
  Filter,
  Printer,
  Edit,
  X,
  // Sliders,
} from "react-feather";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import Table from "src/core/pagination/datatable";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  getTransferStockIn,
  getTransferStockInDetail,
  reset,
  toggleModalEdit,
  toggleModalPrint,
  transferStockInCancel,
  // toggleModalFollowUp,
} from "src/redux/store/transferStockIn";
import Header from "./Header";
import AddModal from "./AddModal";
import FollowUpModal from "./FollowUpModal";
import moment from "moment";
import { getWarehouse } from "src/redux/store/warehouse";
import PrintModal from "./PrintModal";
import { getTransferStockOut } from "src/redux/store/transferStockOut";
import EditModal from "./EditModal";

const renderStatus = (val) => {
  if (val == "received") return <span className="badge bg-success">{val}</span>;
  if (val == "cancelled") return <span className="badge bg-danger">{val}</span>;
  if (val == "canceled") return <span className="badge bg-danger">{val}</span>;
  return val;
};

const StockTransfer = () => {
  //   const data = useSelector((state) => state.stocktransferdata);
  const transferStockIn = useSelector((state) => state.transferStockIn);
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);
  const [q, setQ] = useState("");
  const [search, setSearch] = useState("");

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    dispatch(
      getTransferStockIn({
        limit,
        // offset: skip,
        page,
        stock_order_code: q,
        order: "stock_order_code",
      })
    );
  }, [limit, skip, q, dispatch, page]);

  useEffect(() => {
    dispatch(getWarehouse());
    dispatch(
      getTransferStockOut({
        limit: 99999,
        // offset: skip,
        page: 1,
        stock_order_code: "",
        order: "stock_order_code",
      })
    );
  }, [dispatch]);

  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleChange = (pagination, filters, sorter) => {
    const offset =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const limit = pagination.pageSize;

    setSkip(offset);
    setLimit(limit);
    setPage(pagination.current);
  };

  // const options = [
  //   { value: "sortByDate", label: "Sort by Date" },
  //   { value: "140923", label: "14 09 23" },
  //   { value: "110923", label: "11 09 23" },
  // ];

  const warehouseOptions = [
    { value: "Lobar Handy", label: "Lobar Handy" },
    { value: "Quaint Warehouse", label: "Quaint Warehouse" },
    { value: "Traditional Warehouse", label: "Traditional Warehouse" },
    { value: "Cool Warehouse", label: "Cool Warehouse" },
  ];

  const destinationOptions = [
    { value: "Selosy", label: "Selosy" },
    { value: "Logerro", label: "Logerro" },
    { value: "Vesloo", label: "Vesloo" },
    { value: "Crompy", label: "Crompy" },
  ];

  const columns = [
    {
      title: "#",
      key: "index",
      render: (value, item, index) => (page - 1) * limit + index + 1,
    },
    {
      title: "No SMB",
      dataIndex: "stock_order_code",
      sorter: (a, b) => a.stock_order_code.localeCompare(b.stock_order_code),
      render: (text, item, value) => {
        return (
          <div
            role="button"
            className="me-2 p-2"
            onClick={() =>
              dispatch(
                getTransferStockInDetail({
                  stock_order_id: item.stock_order_id,
                  stock_order_note: item.stock_order_note,
                  warehouse_name: item.warehouse_name,
                  stock_order_code: item.stock_order_code,
                  stock_order_date: item.stock_order_date,
                  modal: true,
                })
              )
            }
          >
            <u>{item.stock_order_code}</u>
          </div>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "stock_order_date",
      width: 150,
      minWidth: 150,
      sorter: (a, b) =>
        moment(a.stock_order_date).unix() - moment(b.stock_order_date).unix(),

      // sorter: (a, b) => a.toWarehouse.length - b.toWarehouse.length,
    },
    {
      title: "Reference PO",
      dataIndex: "stock_order_reference_name",
      sorter: (a, b) =>
        a.stock_order_reference_name.localeCompare(
          b.stock_order_reference_name
        ),
    },
    {
      title: "Status",
      dataIndex: "stock_order_status",
      render: (text, item, value) => {
        return renderStatus(item.status_received);
      },
      sorter: (a, b) => a.stock_order_status - b.stock_order_status,
    },
    // {
    //   title: "User",
    //   dataIndex: "quantityTransferred",
    //   // sorter: (a, b) =>
    //   //   a.quantityTransferred.length - b.quantityTransferred.length,
    // },
    // {
    //   title: "Print",
    //   dataIndex: "print",
    //   render: (text, item, value) => {
    //     return (
    //       <div className="action-table-data">
    //         <div className="">
    //           {/* <div className="input-block add-lists"></div> */}

    //           <a
    //             role="button"
    //             className="me-2 p-2"
    //             // onClick={() => {
    //             //   dispatch(
    //             //     getTransferStockInDetail({
    //             //       // stock_order_id: item.stock_order_id,
    //             //       stock_order_id: 2,
    //             //     })
    //             //   );
    //             //   dispatch(toggleModalPrint());
    //             // }}
    //             // to="#"
    //             // data-bs-toggle="modal"
    //             // data-bs-target="#edit-units"
    //           >
    //             <Printer className="text-info" size={14} />
    //           </a>
    //         </div>
    //       </div>
    //     );
    //   },
    //   // sorter: (a, b) => a.createdby.length - b.createdby.length,
    // },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, item, value) => (
        <div className="action-table-data">
          <div className="d-flex gap-2">
            <div className="input-block add-lists"></div>
            {/* <div
              className="me-2 p-2"
              role="button"
              onClick={() => {
                dispatch(toggleModalEdit());
              }}
            >
              <Edit className="feather-edit" />
            </div> */}
            <div
              role="button"
              className="confirm-text p-2"
              // to="#"
              onClick={() => handleDelete(item)}
            >
              <X className="feather-trash-2 text-danger" />
            </div>
          </div>
        </div>
      ),
    },
  ];

  const MySwal = withReactContent(Swal);

  const handleDelete = (item) => {
    MySwal.fire({
      title: `Cancel this data? <br/><code>${item.stock_order_code}</code>`,
      text: "",
      showCancelButton: true,
      icon: "warning",
      // cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await dispatch(
          transferStockInCancel({
            stock_order_id: item.stock_order_id,
          })
        ).unwrap();
        // console.log("confirmed");
      } else {
        // console.log("canceled");
      }
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setQ(search);
  };

  useEffect(() => {
    dispatch(reset());
  }, []);

  return (
    <div className="page-wrapper">
      <div className="content">
        <Header />
        {/* <Breadcrumbs
          maintitle="Stock Adjustment"
          subtitle="Manage your stock adjustment"
          addButton="Add New"
          importbutton="Import Transfer"
        /> */}
        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <form onSubmit={handleSearch}>
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <button className="btn btn-searchset" type="submit">
                      <i data-feather="search" className="feather-search" />
                    </button>
                  </div>
                </form>
              </div>
              {/* <div className="search-path">
                <Link
                  className={`btn btn-filter ${
                    isFilterVisible ? "setclose" : ""
                  }`}
                  id="filter_search"
                >
                  <Filter
                    className="filter-icon"
                    onClick={toggleFilterVisibility}
                  />
                  <span onClick={toggleFilterVisibility}>
                    <ImageWithBasePath
                      src="assets/img/icons/closes.svg"
                      alt="img"
                    />
                  </span>
                </Link>
              </div> */}
              {/* <div className="form-sort stylewidth">
                <Sliders className="info-img" />
                <Select
                  classNamePrefix="react-select"
                  className="img-select"
                  options={options}
                  placeholder="Sort by Date"
                />
              </div> */}
            </div>
            {/* /Filter */}
            <div
              className={`card${isFilterVisible ? " visible" : ""}`}
              id="filter_inputs"
              style={{ display: isFilterVisible ? "block" : "none" }}
            >
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="input-blocks">
                      <Archive className="info-img" />
                      <Select
                        className="img-select"
                        classNamePrefix="react-select"
                        options={warehouseOptions}
                        placeholder="Warehouse From"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="input-blocks">
                      <User className="info-img" />
                      <Select
                        className="img-select"
                        classNamePrefix="react-select"
                        options={destinationOptions}
                        placeholder="Warehouse To"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="input-blocks">
                      <Calendar className="info-img" />
                      <div className="input-groupicon">
                        <DatePicker
                          selected={selectedDate}
                          onChange={handleDateChange}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Choose Date"
                          className="datetimepicker"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                    <div className="input-blocks">
                      <a className="btn btn-filters ms-auto">
                        {" "}
                        <i
                          data-feather="search"
                          className="feather-search"
                        />{" "}
                        Search{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Filter */}
            <div className="table-responsive">
              <Table
                tableClassName="tight-table"
                columns={columns}
                dataSource={transferStockIn.data}
                loading={transferStockIn.loading}
                total={transferStockIn.total}
                handleChange={handleChange}
                pageSize={limit}
              />
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>
      <AddModal />
      <EditModal />
      <FollowUpModal />
      <PrintModal />
    </div>
  );
};

export default StockTransfer;
