import React from "react";
import { PlusCircle } from "react-feather";
import { useDispatch } from "react-redux";
import { toggleModalAdd } from "src/redux/store/transferStockOut";
const mainTitle = "Search Product";
const subtitle = "Find your Product";

const Header = () => {
   const dispatch = useDispatch();
   return (
      <div className="page-header">
         <div className="add-item d-flex">
            <div className="page-title">
               <h4>{mainTitle}</h4>
               <h6>{subtitle}</h6>
            </div>
         </div>
      </div>
   );
};

export default Header;
