import React, { useEffect, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
// import ImageWithBasePath from "src/core/img/imagewithbasebath";
import //   toggleModalCashPayment,
// toggleModalCompletedPayment,
"src/redux/store/pos";
import {
  getTransaction,
  getTransactionDetail,
  toggleModalReprint,
  toggleModalTransaction,
  toggleModalRetur,
  setModalTransaction,
  setModalReturOptions,
  toggleModalReprintRetur,
  getReturDetail,
} from "src/redux/store/transaction";

import Table from "src/core/pagination/datatable";
import "./modal.css";
import { rupiahCurrency } from "src/helper/currency";
import { Printer, RefreshCw } from "react-feather";
// import warehouse from "src/redux/store/warehouse";

const renderStatus = (status) => {
  if (status == 1) return <span className="badge bg-success">Paid</span>;
};

const renderType = (type) => {
  return <span className="badge bg-secondary">{type}</span>;
};

const Transaction = () => {
  const transaction = useSelector((state) => state.transaction);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [q, setQ] = useState("");
  const [search, setSearch] = useState("");
  const [tab, setTab] = useState("purchase");

  const handle = () => {
    setLimit(10);
    setPage(10);
  };

  useEffect(() => {
    if (transaction.modalTransaction) {
      dispatch(
        getTransaction({
          limit,
          offset: skip,
          billing_code: q,
          order: "billing_id",
          type: tab == "return" ? "retur" : "",
        })
      );
    }
  }, [transaction.modalTransaction, limit, skip, q, dispatch, tab]);

  const renderPrintStruck = (props) => (
    <Tooltip id="pdf-tooltip" {...props} onClick={handle}>
      Print Struck
    </Tooltip>
  );

  const renderRetur = (props) => (
    <Tooltip id="pdf-tooltip" {...props} onClick={handle}>
      Retur / Exchange
    </Tooltip>
  );

  const renderPrintRetur = (props) => (
    <Tooltip id="pdf-tooltip" {...props} onClick={handle}>
      Detail Retur
    </Tooltip>
  );

  const handleClickRetur = (billing_id) => {
    dispatch(
      getTransactionDetail({
        billing_id,
      })
    );
    dispatch(setModalTransaction(false));
    dispatch(setModalReturOptions(true));
  };

  const columns = [
    {
      title: "#",
      key: "index",
      render: (value, item, index) => (page - 1) * limit + index + 1,
    },
    {
      title: "Billing Code",
      dataIndex: "billing_code",
      sorter: (a, b) => a.billing_code.localeCompare(b.billing_code),
    },
    {
      title: "Billing Date",
      dataIndex: "billing_date",
      //   render: (value, item, index) => (page - 1) * limit + index + 1,
    },
    {
      title: "Status",
      dataIndex: "billing_status",
      render: (value, item, index) => {
        return <span>{renderStatus(item.billing_status)}</span>;
      },
    },
    {
      title: "Payment Type",
      dataIndex: "billing_paid_type",
      render: (value, item, index) => {
        // return <span>{renderType(item.billing_paid_type)}</span>;
        return (
          <div className="d-flex flex-column">
            {item.billing_payments?.map((item, key) => (
              <div key={key} className="d-flex gap-2 align-items-center">
                <div
                  className="bg-success"
                  style={{ height: "5px", width: "5px", borderRadius: "50%" }}
                ></div>
                <small>{item.payment_method}</small>
              </div>
            ))}
          </div>
        );
      },
    },
    {
      title: "Amount Paid",
      dataIndex: "billing_paid",
      render: (value, item, index) => {
        return <span>{rupiahCurrency.format(item.billing_paid)}</span>;
      },
      sorter: (a, b) => a.billing_paid - b.billing_paid,
    },
    // {
    //   title: "Discount",
    //   dataIndex: "discount",
    //   render: (value, item, index) => {
    //     return (
    //       <span className="text-center">
    //         {item.discount > 0 ? rupiahCurrency.format(item.discount) : `-`}
    //       </span>
    //     );
    //   },
    //   sorter: (a, b) => a.discount - b.discount,
    // },
    {
      title: "Grand Total",
      dataIndex: "grandtotal",
      render: (value, item, index) => {
        return (
          <span>{rupiahCurrency.format(item.aggregated_data.grandtotal)}</span>
        );
      },
      sorter: (a, b) => a.grandtotal - b.grandtotal,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (value, item, index) => {
        return (
          <div className="d-flex gap-2 align-items-center">
            <OverlayTrigger placement="top" overlay={renderPrintStruck}>
              <div className="action-table-data">
                <div className="edit-delete-action">
                  <div className="input-block add-lists"></div>

                  <a
                    role="button"
                    className="me-2 p-2"
                    onClick={() => {
                      dispatch(
                        getTransactionDetail({
                          billing_id: item.billing_id,
                        })
                      );
                      dispatch(toggleModalReprint());
                    }}
                  >
                    <Printer className="feather-edit" />
                  </a>
                </div>
              </div>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={renderRetur}>
              <div className="action-table-data">
                <div className="edit-delete-action">
                  <div className="input-block add-lists"></div>

                  <a
                    role="button"
                    className="me-2 p-2"
                    onClick={() => handleClickRetur(item.billing_id)}
                    // onClick={() => {
                    //   dispatch(
                    //     getTransactionDetail({
                    //       billing_id: item.billing_id,
                    //     })
                    //   );
                    //   dispatch(toggleModalRetur());
                    // }}
                  >
                    <RefreshCw className="feather-edit" />
                  </a>
                </div>
              </div>
            </OverlayTrigger>
          </div>
        );
      },
    },
  ];

  const returColumns = [
    {
      title: "#",
      key: "index",
      render: (value, item, index) => (page - 1) * limit + index + 1,
    },
    {
      title: "Billing Code",
      dataIndex: "billing_code",
      sorter: (a, b) => a.billing_code.localeCompare(b.billing_code),
    },
    {
      title: "Reason",
      dataIndex: "reason",
      //   render: (value, item, index) => (page - 1) * limit + index + 1,
    },
    {
      title: "Note",
      dataIndex: "note",
      //   render: (value, item, index) => (page - 1) * limit + index + 1,
    },
    {
      title: "Amount Refund",
      dataIndex: "total_refund",
      render: (value, item, index) => {
        return <span>{rupiahCurrency.format(item.total_refund)}</span>;
      },
      sorter: (a, b) => a.billing_paid - b.billing_paid,
    },
    // {
    //   title: "Status",
    //   dataIndex: "billing_status",
    //   render: (value, item, index) => {
    //     return <span>{renderStatus(item.billing_status)}</span>;
    //   },
    // },
    // {
    //   title: "Payment Type",
    //   dataIndex: "billing_paid_type",
    //   render: (value, item, index) => {
    //     return <span>{renderType(item.billing_paid_type)}</span>;
    //   },
    // },
    // {
    //   title: "Amount Paid",
    //   dataIndex: "billing_paid",
    //   render: (value, item, index) => {
    //     return <span>{rupiahCurrency.format(item.billing_paid)}</span>;
    //   },
    //   sorter: (a, b) => a.billing_paid - b.billing_paid,
    // },
    // {
    //   title: "Discount",
    //   dataIndex: "discount",
    //   render: (value, item, index) => {
    //     return (
    //       <span className="text-center">
    //         {item.discount > 0 ? rupiahCurrency.format(item.discount) : `-`}
    //       </span>
    //     );
    //   },
    //   sorter: (a, b) => a.discount - b.discount,
    // },
    // {
    //   title: "Grand Total",
    //   dataIndex: "grandtotal",
    //   render: (value, item, index) => {
    //     return (
    //       <span>{rupiahCurrency.format(item.aggregated_data.grandtotal)}</span>
    //     );
    //   },
    //   sorter: (a, b) => a.grandtotal - b.grandtotal,
    // },
    {
      title: "Action",
      dataIndex: "action",
      render: (value, item, index) => {
        return (
          <div className="d-flex gap-2 align-items-center">
            <OverlayTrigger placement="top" overlay={renderPrintRetur}>
              <div className="action-table-data">
                <div className="edit-delete-action">
                  <div className="input-block add-lists"></div>

                  <a
                    role="button"
                    className="me-2 p-2"
                    onClick={() => {
                      dispatch(getReturDetail(item.id));
                      dispatch(toggleModalReprintRetur());
                    }}
                  >
                    <Printer className="feather-edit" />
                  </a>
                </div>
              </div>
            </OverlayTrigger>
          </div>
        );
      },
    },
  ];

  const handleChange = (pagination, filters, sorter) => {
    const offset =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const limit = pagination.pageSize;

    setSkip(offset);
    setLimit(limit);
    setPage(pagination.current);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setQ(search);
  };

  const handleBack = () => {
    dispatch(setModalTransaction(false));
  };

  return (
    <Modal
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={transaction.modalTransaction}
      onHide={handleBack}
    >
      <Modal.Header closeButton>
        <Modal.Title>Transaction List</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tabs-sets">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${tab == "purchase" ? `active` : ``}`}
                id="purchase-tab"
                data-bs-toggle="tab"
                data-bs-target="#purchase"
                type="button"
                aria-controls="purchase"
                aria-selected="true"
                role="tab"
                onClick={() => setTab("purchase")}
              >
                Purchase
              </button>
            </li>
            {/* <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="payment-tab"
                data-bs-toggle="tab"
                data-bs-target="#payment"
                type="button"
                aria-controls="payment"
                aria-selected="false"
                role="tab"
              >
                Payment
              </button>
            </li> */}
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${tab == "return" ? `active` : ``}`}
                id="return-tab"
                data-bs-toggle="tab"
                data-bs-target="#return"
                type="button"
                aria-controls="return"
                aria-selected="false"
                role="tab"
                onClick={() => setTab("return")}
              >
                Return
              </button>
            </li>
          </ul>
          <div className="tab-content">
            <div
              className={`tab-pane fade ${
                tab == "purchase" ? `show active` : ``
              }`}
              // className="tab-pane fade show active"
              id="purchase"
              role="tabpanel"
              aria-labelledby="purchase-tab"
            >
              <div className="table-top">
                <div className="search-set">
                  <form onSubmit={handleSearch}>
                    <div className="search-input">
                      <input
                        type="text"
                        placeholder="Search"
                        className="form-control form-control-sm formsearch"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <button className="btn btn-searchset" type="submit">
                        <i data-feather="search" className="feather-search" />
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <Table
                key={"table-purchase"}
                tableClassName={"tight-table"}
                columns={columns}
                dataSource={transaction.data}
                loading={transaction.loading}
                total={transaction.total}
                handleChange={handleChange}
              />
            </div>
            <div
              className={`tab-pane fade ${
                tab == "return" ? `show active` : ``
              }`}
              id="return"
              role="tabpanel"
            >
              <div className="table-top">
                <div className="search-set">
                  <form onSubmit={handleSearch}>
                    <div className="search-input">
                      <input
                        type="text"
                        placeholder="Search"
                        className="form-control form-control-sm formsearch"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <button className="btn btn-searchset" type="submit">
                        <i data-feather="search" className="feather-search" />
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <Table
                key={"table-retur"}
                tableClassName={"tight-table"}
                columns={returColumns}
                dataSource={transaction.data}
                loading={transaction.loading}
                total={transaction.total}
                handleChange={handleChange}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Transaction;
