import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import "./modal.css";
import { useDispatch, useSelector } from "react-redux";
import { setActiveStaff } from "src/redux/store/pos";
import { useNavigate } from "react-router-dom";

// import ImageWithBasePath from "src/core/img/imagewithbasebath";

const SelectStaff = ({ open, setOpen }) => {
  const pos = useSelector((state) => state.pos);
  const users = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [staff, setStaff] = useState(null);

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={pos.modalSelectStaff}
    >
      <Modal.Header>
        <div className="d-flex flex-column">
          <Modal.Title>Select staff on duty</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        {users.listAbsen.length > 0 ? (
          <Select
            placeholder="Select sfaff on duty"
            classNamePrefix="react-select"
            defaultValue={null}
            options={users.listAbsen.map((item) => {
              return {
                label: item?.user?.name,
                value: item?.user_id,
              };
            })}
            onChange={setStaff}
          />
        ) : (
          <p>There no staff to select</p>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center gap-1">
        {users.listAbsen.length > 0 ? (
          <Button
            variant="secondary"
            disabled={!staff}
            onClick={() =>
              dispatch(setActiveStaff({ id: staff.value, name: staff.label }))
            }
          >
            Process
          </Button>
        ) : (
          <Button variant="secondary" onClick={() => navigate("/dashboard")}>
            Check In First
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default SelectStaff;
