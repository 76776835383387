// src/features/userSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "src/config/axios";

const initialState = {
  data: [],
  total: 0,
  params: {},
  loading: false,

  listUser: [],
  loadingListUser: [],
  listAbsen: [],
  loadingListAbsen: [],
};

export const getUser = createAsyncThunk("user", async (params) => {
  try {
    let url = `https://dummyjson.com/users?limit=${params.limit}&skip=${params.skip}&select=firstName,email,role,image,phone`;
    if (params.filter) {
      url = `https://dummyjson.com/users/search?q=${params.filter}`;
    }
    const response = await axios.get(url);
    // const result = response.data;
    // return result;
    return {
      data: response.data.users,
      total: response.data.total,
      params,
    };
  } catch (error) {
    console.log(error);
  }
});

export const createUser = createAsyncThunk(
  "user/create",
  async (data, { dispatch, getState }) => {
    try {
      const response = await axios.post(
        `https://dummyjson.com/users/add`,
        {
          data,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      await dispatch(getUser(getState().user.params));
      const result = response.data;
      return result;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getListUser = createAsyncThunk("user/list", async (params) => {
  try {
    // let url = `https://dummyjson.com/users?limit=${params.limit}&skip=${params.skip}&select=firstName,email,role,image,phone`;
    // if (params.filter) {
    //   url = `https://dummyjson.com/users/search?q=${params.filter}`;
    // }
    const token = localStorage.getItem("token");
    const response = await axios.get(
      `${process.env.REACT_APP_API}/v1/user/index`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      }
    );
    // const result = response.data;
    // return result;
    return {
      data: response.data.data,
      params,
    };
  } catch (error) {
    console.log(error);
  }
});

export const getListAbsen = createAsyncThunk(
  "user/absent-list",
  async (params) => {
    try {
      const token = localStorage.getItem("token");
      const store = JSON.parse(localStorage.getItem("store"));
      const response = await axios.get(
        `${process.env.REACT_APP_API}/v1/user/absent`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            txtWarehouseId: store.store,
          },
        }
      );
      return {
        data: response.data.data,
        params,
      };
    } catch (error) {
      console.log(error);
    }
  }
);

export const storeAbsen = createAsyncThunk(
  "user/absent-store",
  async (payload, { dispatch, getState }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_API}/v1/absent/store`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(getListAbsen());
      return {
        data: response.data.data,
        payload,
      };
    } catch (error) {
      console.log(error);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.total = action.payload.total;
      state.params = action.payload.params;
      state.loading = false;
    });
    builder.addCase(getUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUser.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(createUser.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createUser.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getListUser.fulfilled, (state, action) => {
      state.loadingListUser = false;
      state.listUser = action.payload.data;
    });
    builder.addCase(getListUser.pending, (state) => {
      state.loadingListUser = true;
    });
    builder.addCase(getListUser.rejected, (state) => {
      state.loadingListUser = false;
    });

    builder.addCase(getListAbsen.fulfilled, (state, action) => {
      state.loadingListAbsen = false;
      state.listAbsen = action.payload.data;
    });
    builder.addCase(getListAbsen.pending, (state) => {
      state.loadingListAbsen = true;
    });
    builder.addCase(getListAbsen.rejected, (state) => {
      state.loadingListAbsen = false;
    });

    builder.addCase(storeAbsen.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(storeAbsen.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(storeAbsen.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default userSlice.reducer;
