import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { storeAbsen } from "src/redux/store/user";

const salesList = [
  {
    label: "John Doe",
    value: "John Doe",
  },
  {
    label: "John Wick",
    value: "John Wick",
  },
];

export default function UserSelect({ setChecked }) {
  const [selected, setSelected] = useState({
    label: "",
    value: "",
  });
  const user = useSelector((store) => store.user);
  const store = JSON.parse(localStorage.getItem("store"));
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    const txtUserId = selected.value;
    const txtDate = moment().format("YYYY-MM-DD");
    const txtCheckIn = moment().format("HH:mm:ss");
    const payload = {
      txtUserId,
      txtDate,
      txtCheckIn,
      txtWarehouseId: store.store,
    };
    dispatch(storeAbsen(payload));
    setSelected({
      label: "",
      value: "",
    });
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="d-flex align-items-center justify-content-between gap-4">
        <div className="input-blocks">
          <label>Sales</label>
          <Select
            classNamePrefix="react-select"
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              container: (base) => ({
                ...base,
                width: 250,
                maxWidth: 300, // Set the width to 200px
              }),
            }}
            options={user.listUser.map((item) => {
              return {
                value: item.id,
                label: item.name,
              };
            })}
            value={selected}
            placeholder="Choose sales name"
            // options={warehouse.data
            //   .filter((item) => item.warehouse_id !== store.store)
            //   .map((item) => {
            //     return {
            //       value: item.warehouse_id,
            //       label: `${item.warehouse_code} | ${item.warehouse_name}`,
            //     };
            //   })}
            // value={transferStockIn.addItem.store}
            onChange={(e) =>
              setSelected({
                value: e.value,
                label: e.label,
              })
            }
          />
        </div>

        <button
          className="btn btn-primary mt-0"
          disabled={user.loading || selected == null}
          type="submit"
        >
          {user.loading ? `Processing..` : `Check In`}
        </button>
      </div>
    </form>
  );
}
