import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export function printReceipt(element) {
  const domElement = document.getElementById(element);
  html2canvas(domElement, { scale: 1.1 }).then((canvas) => {
    const DPI = 96;
    // const inch = 25.4;
    const inch = 18;
    const img = canvas.toDataURL("image/png");
    const pdf = new jsPDF({
      orientation: "p", // portrait
      unit: "mm", // points, pixels won't work properly
      format: [80, Math.round((canvas.height / DPI) * inch)], // set needed dimensions for any element
    });
    const pdfWidth = pdf.internal.pageSize.getWidth();
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, 0);
    window.open(pdf.output("bloburl"));
  });
}

export function printStock(element) {
  const domElement = document.getElementById(element);
  html2canvas(domElement, { scale: 1.1 }).then((canvas) => {
    const DPI = 96;
    // const inch = 25.4;
    const inch = 13;
    const img = canvas.toDataURL("image/png");
    const pdf = new jsPDF({
      orientation: "p", // portrait
      unit: "mm", // points, pixels won't work properly
      format: [80, Math.round((canvas.height / DPI) * inch)], // set needed dimensions for any element
    });
    const pdfWidth = pdf.internal.pageSize.getWidth();
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, 0);
    window.open(pdf.output("bloburl"));
  });
}

export function printLetter(element) {
  const domElement = document.getElementById(element);
  html2canvas(domElement, { scale: 2 }).then((canvas) => {
    const DPI = 200;
    // const inch = 25.4;
    const inch = 18;
    const img = canvas.toDataURL("image/png");
    const pdf = new jsPDF({
      orientation: "p", // portrait
      unit: "mm", // points, pixels won't work properly
      format: [80, Math.round((canvas.height / DPI) * inch)], // set needed dimensions for any element
    });
    const pdfWidth = pdf.internal.pageSize.getWidth();
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, 0);
    window.open(pdf.output("bloburl"));
  });
}
