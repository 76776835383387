import React from "react";
import { calculateVAT, receiptCurrency } from "src/helper/currency";
import Header from "./receipt/Header";
// import Footer from "./receipt/Footer";
import { useSelector } from "react-redux";

const PrintableComponent = () => {
  const transferStockOut = useSelector((state) => state.transferStockOut);
  return (
    // <div className="print-receipts-wrapper">
    <div className="printable-component" id="print-receipts">
      <Header grandTotal={0} />
      <div
        className="text-center fs-4 fw-bolder"
        style={{ marginBottom: "40px", marginTop: "40px" }}
      >
        Outgoing Goods
      </div>

      <table className="w-100 table-fit">
        <thead>
          <tr style={{ borderBottom: "1px solid #DDD" }}>
            <th
              style={{ width: "40%", paddingBottom: "10px", fontSize: "12px" }}
            >
              Items
            </th>
            <th
              className="text-center"
              style={{
                width: "20%",
                paddingBottom: "10px",
                paddingRight: "10px",
                fontSize: "12px",
              }}
            >
              Qty
            </th>
            <th
              className="text-end"
              style={{
                width: "20%",
                paddingBottom: "10px",
                paddingRight: "10px",
                fontSize: "12px",
              }}
            >
              Qty Outstanding
            </th>
            <th
              className="text-center"
              style={{ width: "20%", paddingBottom: "10px", fontSize: "12px" }}
            >
              Notes
            </th>
          </tr>
        </thead>
        <tbody className="content-row">
          {transferStockOut.detail.data.map((item, key) => (
            <tr
              key={key}
              className={`${
                key != transferStockOut.detail.data.length - 1
                  ? `border-bottom-dashed`
                  : ``
              }`}
            >
              <td className="py-2">
                <div className="fw-bold">{item.product_name}</div>
                <div className="fw-light">{item.product_alias}</div>
                <div>{item.product_size}</div>
                <div>{item.product_code}</div>
              </td>
              <td
                className="text-center fw-bold fs-6"
                style={{ paddingBottom: "10px", paddingRight: "15px" }}
              >
                {item.qty}
              </td>
              <td
                className="text-center fw-bold fs-6"
                style={{ paddingBottom: "10px", paddingRight: "15px" }}
              >
                {item.qty_outstanding}
              </td>
              <td className="text-center">{item.stock_order_detail_note}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="d-flex align-items-center justify-content-end mt-4 gap-2 fs-6">
        <div className="fw-bolder">Total :</div>
        <div>
          {transferStockOut.detail.data.reduce(
            (accumulator, item) => accumulator + parseInt(item.qty),
            0
          )}{" "}
          products
        </div>
      </div>

      <div className="my-5">
        <label className="form-label">Notes</label>
        <div className="print-receipts-note">
          {transferStockOut.detail.stock_order_note}
        </div>
      </div>
      {/* <Footer billingCode={"[billing_code]"} transDate={"[billing_date]"} /> */}
    </div>
    // </div>
  );
};

export default PrintableComponent;
