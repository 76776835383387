import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  Archive,
  Calendar,
  User,
} from "react-feather";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import Table from "src/core/pagination/datatable";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  getSearchProduct,
} from "src/redux/store/productSearch";
import Header from "./Header";

const renderStatus = (val) => {
   if (val == "created")
      return <span className="badge bg-warning">Created</span>;
   if (val == "partially received")
      return <span className="badge bg-secondary">Partially Received</span>;
   if (val == "close") return <span className="badge bg-success">Close</span>;
};

const SearchProductPage = () => {
   const productSearch = useSelector((state) => state.productSearch);
   const dispatch = useDispatch();
   const [limit, setLimit] = useState(10);
   const [skip, setSkip] = useState(0);
   const [page, setPage] = useState(1);
   const [q, setQ] = useState("");
   const [search, setSearch] = useState("");

   const [isFilterVisible, setIsFilterVisible] = useState(false);
   const [selectedDate, setSelectedDate] = useState(null);

   useEffect(() => {
      dispatch(
         getSearchProduct({
         limit,
         page,
         search_product: q,
         order: "search_product",
         // stock_order_code: q,
         // order: "stock_order_code",
         })
      );
   }, [limit, skip, q, dispatch, page]);

   const toggleFilterVisibility = () => {
      setIsFilterVisible((prevVisibility) => !prevVisibility);
   };

   const handleDateChange = (date) => {
      setSelectedDate(date);
   };

   const handleChange = (pagination, filters, sorter) => {
      const offset =
         pagination.current * pagination.pageSize - pagination.pageSize;
      const limit = pagination.pageSize;

      setSkip(offset);
      setLimit(limit);
      setPage(pagination.current);
   };

   const warehouseOptions = [
      { value: "Lobar Handy", label: "Lobar Handy" },
      { value: "Quaint Warehouse", label: "Quaint Warehouse" },
      { value: "Traditional Warehouse", label: "Traditional Warehouse" },
      { value: "Cool Warehouse", label: "Cool Warehouse" },
   ];

   const destinationOptions = [
      { value: "Selosy", label: "Selosy" },
      { value: "Logerro", label: "Logerro" },
      { value: "Vesloo", label: "Vesloo" },
      { value: "Crompy", label: "Crompy" },
   ];

   const columns = [
      {
         title: "No",
         key: "index",
         render: (value, item, index) => (page - 1) * limit + index + 1,
      },
      {
         title: "Product Code",
         dataIndex: "product_code",
         width: 200,
         minWidth: 200,
      },
      {
         title: "Product Name",
         dataIndex: "product_fullname",
         width: 150,
         minWidth: 150,
      },
      {
         title: "Color",
         dataIndex: "color",
         width: 150,
         minWidth: 150,
      },
      {
         title: "Size",
         dataIndex: "size",
         width: 150,
         minWidth: 150,
      },
      {
         title: "Oberoi",
         dataIndex: "Oberoi",
         width: 150,
         minWidth: 150,
      },
      {
         title: "Petitenget",
         dataIndex: "Petitenget",
         width: 150,
         minWidth: 150,
      },
      {
         title: "Canggu",
         dataIndex: "Canggu",
         width: 150,
         minWidth: 150,
      },
      {
         title: "Main Warehouse",
         dataIndex: "Main Warehouse",
         width: 150,
         minWidth: 150,
      },
   ];

   const MySwal = withReactContent(Swal);

   const handleDelete = (item) => {
      MySwal.fire({
         title: `Cut off this data? <br/><code>${item.search_product}</code>`,
         text: "",
         showCancelButton: true,
         icon: "warning",
         // cancelButtonColor: "#ff0000",
         cancelButtonText: "Cancel",
         confirmButtonColor: "#00ff00",
         confirmButtonText: "Yes",
      }).then(async (result) => {
         if (result.isConfirmed) {
         const res = await dispatch(
            // searchProductCutOff({ stock_order_id: item.stock_order_id })
         ).unwrap();
         // console.log("confirmed");
         } else {
         // console.log("canceled");
         }
      });
   };

   const handleSearch = (e) => {
      e.preventDefault();
      setQ(search);
   };

   return (
      <div className="page-wrapper">
         <div className="content">
         <Header />

         {/* Search Product */}
         <div className="card table-list-card">
            <div className="card-body">
               <div className="table-top">
                  <div className="search-set">
                     <form onSubmit={handleSearch}>
                        <div className="search-input">
                        <input
                           type="text"
                           placeholder="Search"
                           className="form-control form-control-sm formsearch"
                           value={search}
                           onChange={(e) => setSearch(e.target.value)}
                        />
                        <button className="btn btn-searchset" type="submit">
                           <i data-feather="search" className="feather-search" />
                        </button>
                        </div>
                     </form>
                  </div>
               </div>
               {/* /Filter */}
               <div
               className={`card${isFilterVisible ? " visible" : ""}`}
               id="filter_inputs"
               style={{ display: isFilterVisible ? "block" : "none" }}
               >
                  <div className="card-body pb-0">
                     <div className="row">
                        <div className="col-lg-3 col-sm-6 col-12">
                           <div className="input-blocks">
                              <Archive className="info-img" />
                              <Select
                                 className="img-select"
                                 classNamePrefix="react-select"
                                 options={warehouseOptions}
                                 placeholder="Warehouse From"
                              />
                           </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-12">
                           <div className="input-blocks">
                              <User className="info-img" />
                              <Select
                                 className="img-select"
                                 classNamePrefix="react-select"
                                 options={destinationOptions}
                                 placeholder="Warehouse To"
                              />
                           </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-12">
                           <div className="input-blocks">
                              <Calendar className="info-img" />
                              <div className="input-groupicon">
                                 <DatePicker
                                 selected={selectedDate}
                                 onChange={handleDateChange}
                                 dateFormat="dd/MM/yyyy"
                                 placeholderText="Choose Date"
                                 className="datetimepicker"
                                 />
                              </div>
                           </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                           <div className="input-blocks">
                              <a className="btn btn-filters ms-auto">
                                 {" "}
                                 <i
                                 data-feather="search"
                                 className="feather-search"
                                 />{" "}
                                 Search{" "}
                              </a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               {/* /Filter */}

               <div className="table-responsive">
                  <Table
                     tableClassName={"tight-table"}
                     className="table datanew"
                     columns={columns}
                     dataSource={productSearch.data}
                     loading={productSearch.loading}
                     total={productSearch.total}
                     handleChange={handleChange}
                     pageSize={limit}
                  />
               </div>
            </div>
         </div>
         {/* Search Product */}
         </div>
      </div>
   );
};

export default SearchProductPage;
